import { MessageDescriptor, defineMessages } from 'react-intl';
import { graphql } from 'react-relay';

import type { DeviceFamily } from './__generated__/devicesMessages_device.graphql';

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
graphql`
  fragment devicesMessages_device on Device {
    family
  }
`;

export const deviceFamily: Record<DeviceFamily, MessageDescriptor> =
  defineMessages({
    ATTO: {
      id: 'devices.family.atto',
      defaultMessage: 'Atto',
    },
    CARBON: {
      id: 'devices.family.carbon',
      defaultMessage: 'Carbon',
    },
    FUJI: {
      id: 'devices.family.fuji',
      defaultMessage: 'Platinum Pro',
    },
    NANO: {
      id: 'devices.family.nano',
      defaultMessage: 'Nano',
    },
    NICKEL: {
      id: 'devices.family.nickel',
      defaultMessage: 'Platinum',
    },
    PLATINUM: {
      id: 'devices.family.platinum',
      defaultMessage: 'Platinum',
    },
    VIRTUAL: {
      id: 'devices.family.virtual',
      defaultMessage: 'Virtual',
    },
  });
