/**
 * @generated SignedSource<<094f725a8c0447ece10698b09187251e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type DeviceFamily = "ATTO" | "CARBON" | "FUJI" | "NANO" | "NICKEL" | "PLATINUM" | "VIRTUAL";
import { FragmentRefs } from "relay-runtime";
export type DeviceList_devices$data = ReadonlyArray<{
  readonly error: {
    readonly label: string | null;
  } | null;
  readonly family: DeviceFamily | null;
  readonly handle: string | null;
  readonly id: string;
  readonly name: string | null;
  readonly registered: boolean | null;
  readonly softwareVersion: string | null;
  readonly " $fragmentSpreads": FragmentRefs<"DeviceSoftwareVersion_device" | "DeviceStatus_device" | "RegisterDeviceModal_device">;
  readonly " $fragmentType": "DeviceList_devices";
}>;
export type DeviceList_devices$key = ReadonlyArray<{
  readonly " $data"?: DeviceList_devices$data;
  readonly " $fragmentSpreads": FragmentRefs<"DeviceList_devices">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "DeviceList_devices",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "DeviceError",
      "kind": "LinkedField",
      "name": "error",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "label",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "handle",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "registered",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "family",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "softwareVersion",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DeviceStatus_device"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "RegisterDeviceModal_device"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DeviceSoftwareVersion_device"
    }
  ],
  "type": "Device",
  "abstractKey": null
};

(node as any).hash = "32bbcd07c01edb4c5e53eb0046eebeac";

export default node;
